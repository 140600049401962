<template>
  <div>
    <bread-crumb :crumbData="['评价管理']" :chooseCrumbData="1"></bread-crumb>
    <search-case
      :searchData="searchData"
      :chooseSearch="3"
      @inquireBtnClick="inquireBtnClick"
    ></search-case>
    <div
      style="
        padding: 10px 20px;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
      "
    >
      <div style="margin-right: 5px">
        <el-button type="danger" size="mini" @click="batchDelete">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img
              style="width: 16px; height: 16px"
              :src="require('@/assets/iconImg/logo.png')"
            />
            <span style="margin-left: 3px">批量删除</span>
          </div>
        </el-button>
      </div>
    </div>

    <div style="padding: 20px">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="44"> </el-table-column>
        <el-table-column prop="id" sortable label="ID"></el-table-column>
        <el-table-column
          prop="create_time"
          sortable
          label="创建日期"
        ></el-table-column>
        <el-table-column
          prop="update_time"
          sortable
          label="修改日期"
        ></el-table-column>
        <el-table-column
          prop="member_id.member_name"
          label="会员"
        ></el-table-column>
        <el-table-column
          prop="order_id"
          sortable
          label="订单编号"
        ></el-table-column>
        <el-table-column
          prop="star_level"
          sortable
          label="星级"
        ></el-table-column>
        <el-table-column
          prop="comment_content"
          label="评论内容"
        ></el-table-column>
        <el-table-column prop="is_del" label="是否删除">
          <template slot-scope="scope">
            {{ scope.row.is_del == 0 ? "否" : "是" }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
            <span style="margin: 0 10px"></span>
            <i
              class="el-icon-delete poiner"
              @click="handleDelete(scope.row)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
      <div class="tableBottom">
        <span style=""
          >显示第&nbsp;{{
            tableData.length == 0 ? 0 : (page - 1) * length + 1
          }}&nbsp;至&nbsp;{{
            (page - 1) * length + tableData.length
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
        >

        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <el-button @click="homePage">首页</el-button>
          <el-button @click="upPage">上页</el-button>
          <el-pagination
            background
            layout=" pager,slot"
            :total="tableFinshNum"
            :current-page="page"
            @current-change="currentChange"
          ></el-pagination>
          <el-button @click="nextPage">下页</el-button>
          <el-button @click="endPage">末页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchData: {
        date: "",
        inquirePlaceholder: "输入要查询的内容",
        isInquireValue: true,
        isInquireBtn: true,
      },

      tableData: [],
      multipleSelection: [],
      tableFinshNum: 0,
      page: 1,
      length: 10,
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
  },
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getTabList() {
      this.$http
        .post("/client/Evaluation/lst", {
          commercial_id: this.commercial_id,
          currentPage: this.page,
          currentLength: this.length,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    inquireBtnClick(data) {
      this.$http
        .post("/client/Evaluation/search", {
          firstTime: data.dateRange[0] || "",
          lastTime: data.dateRange[1] || "",
          search: data.inquireValue,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Client/Evaluation/del", {
              id: row.id,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    batchDelete() {
      if (this.multipleSelection.length == 0) return;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const idArr = this.multipleSelection.map((item) => {
            return item.id;
          });
          this.$http
            .post("/Client/Evaluation/bacthDel", {
              idArr,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page <= 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page++;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
  },
  watch: {
    page: {
      handler() {
        this.getTabList();
      },
    },
  },
};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .cell {
  padding: 0 !important;
}
</style>
